import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'qapreview',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'qapreview/:quid',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: ':quid',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: ':url/:quid',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'qapreview',
    pathMatch: 'full'
  },
  {
    path: 'qapreview/q/qvideo',
    loadChildren: () => import('./qvideo/qvideo.module').then( m => m.QvideoPageModule)
  },
  {
    path: 'concept',
    loadChildren: () => import('./concept/concept.module').then( m => m.ConceptPageModule)
  },
  {
    path: 'qapreview/q/rating',
    loadChildren: () => import('./rating/rating.module').then( m => m.RatingPageModule)
  },
  {
    path: 'trysimilar',
    loadChildren: () => import('./trysimilar/trysimilar.module').then( m => m.TrysimilarPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
